import { useState } from "react";
import { toast } from "react-toastify";
import { APIRoutes } from "../../constants/ApiRoutes";
import EncryptApiKey from "../../helper/EncryptApiKey";
import { IoRefresh } from "react-icons/io5";

const trackingStatusMapping = {
  completed: "Completed",
  failed: "Failed",
  pending: "In Progress",
  unknown: "...",
};

const trackingStatusBg = {
  completed: "#00b894",
  failed: "#ff7676",
  pending: "#f5a623",
  unknown: "#8d8d8d",
};

const dateFormatOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};

export const TrackingRow = ({ tracking }) => {
  const [fetchingStatus, setFetchingStatus] = useState(false);
  const [trackingStatus, setTrackingStatus] = useState(tracking?.status ?? "unknown");

  const parseDateAndTime = (dateString) => {
    if (!dateString) return dateString;

    const date = new Date(dateString);
    const formattedDate = date.toLocaleString("en-US", dateFormatOptions);
    const [datePart, timePart] = formattedDate.split(", ");

    const [month, day, year] = datePart.split("/");
    const formattedDatePart = `${day}-${month}-${year}`;

    return `${formattedDatePart}, ${timePart}`;
  };

  const getTrackingStatus = async () => {
    setFetchingStatus(true);

    try {
      const apiKey = await EncryptApiKey();
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}${APIRoutes.getTrackingStatus}?trackingId=${tracking._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apiKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error fetching tracking status", {
          status: response.status,
          statusText: response.statusText,
        });
      }

      const json = await response.json();
      const status = json.data?.status;

      setTrackingStatus(status);
    } catch (error) {
      console.error("Error fetching tracking status: ", { error });
      toast.error(error.message);
    } finally {
      setFetchingStatus(false);
    }
  };

  return (
    <div className="tracking-row-container">
      <div className="tracking-content">
        <div className="tracking-row-upper">
          <p className="file-name">{tracking.fileName || "-"}</p>
          <span
            style={{
              backgroundColor: trackingStatusBg[trackingStatus],
            }}
            className="upload-status"
          >
            {trackingStatusMapping[trackingStatus]}
          </span>
        </div>
        <div className="tracking-row-lower">
          <p className="tracking-id">ID: {tracking._id}</p>
          <p className="last-modified">{parseDateAndTime(tracking.createdAt)}</p>
        </div>
      </div>
      <button
        disabled={fetchingStatus || trackingStatus === "completed"}
        className="refresh-btn"
        onClick={getTrackingStatus}
      >
        <IoRefresh className={`refresh-icon ${fetchingStatus ? "spin" : ""}`} />
      </button>
    </div>
  );
};
