export const requiredError = (headerName, rowNumber, columnNumber) => {
  return `${headerName} is required in row ${rowNumber}, column ${columnNumber}`;
};

export const csvConfig = {
  headers: [
    {
      name: "userAddress",
      inputName: "userAddress",
      required: true,
      requiredError,
    },
    {
      name: "maxMintAllowed",
      inputName: "maxMintAllowed",
      required: true,
      requiredError,
    },
  ],
};

export const filenameRegex = /^(?![.])(?!.*[.]\s|[.]{2})(?!.*\s$)[\w.\s-]+(?<![.])$/;
