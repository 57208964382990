import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { fetchTrackingIds, parseValidationErrors, uploadCsv, validateCsv } from "./csvUploadService";
import { IoCloseCircle } from "react-icons/io5";
import { FaFileCsv } from "react-icons/fa";
import { TbFilesOff } from "react-icons/tb";
import { TrackingRow } from "./TrackingRow";
import { filenameRegex } from "./csvValidateConfigs";

const Tab = ({ tabName, htmlFor, defaultChecked, onChange }) => {
  return (
    <div className="tab">
      <label htmlFor={htmlFor} className="tab-label">
        {tabName}
      </label>
      <input
        type="radio"
        name="tabs"
        id={htmlFor}
        onChange={onChange}
        defaultChecked={defaultChecked}
        className="tab-control"
      />
    </div>
  );
};

export const CsvUploader = ({ contractAddress }) => {
  const [showFile, setShowFile] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [trackings, setTrackings] = useState([]);
  const [activeTab, setActiveTab] = useState("uploadTab");

  useMemo(() => {
    if (errorMessages.length) {
      errorMessages.forEach((errorMessage) => {
        toast.error(errorMessage);
      });
    }
  }, [errorMessages]);

  const handleFileChange = (acceptedFiles) => {
    if (acceptedFiles?.[0]) {
      const selectedFile = acceptedFiles[0];

      if (selectedFile.type !== "text/csv") {
        toast.error("Please upload a csv file.");
        setCsvFile(null);
        return;
      }

      if (!filenameRegex.test(selectedFile.name)) {
        toast.error("Filename contains invalid characters.");
        setCsvFile(null);
        return;
      }

      setCsvFile(selectedFile);
      setShowFile(true);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    onDrop: handleFileChange,
  });

  const handleUpload = async () => {
    setUploading(true);
    const validationResponse = await validateCsv(csvFile);
    console.log({ validationResponse });

    if (!validationResponse.success) {
      setErrorMessages(parseValidationErrors(validationResponse.errorMessage));
      setUploading(false);
      return;
    }

    const uploadResponse = await uploadCsv(csvFile, contractAddress);
    if (!uploadResponse.success) {
      setErrorMessages([uploadResponse.errorMessage]);
      setUploading(false);
      return;
    }

    const response = await fetchTrackingIds(contractAddress);
    if (response.success && response.data) {
      setTrackings(response.data.reverse());
    }

    toast.success("CSV uploaded successfully.");
    setCsvFile(null);
    setShowFile(false);
    setErrorMessages([]);
    setUploading(false);
  };

  useEffect(() => {
    const fetchTrackings = async () => {
      const response = await fetchTrackingIds(contractAddress);
      if (response.success && response.data) {
        setTrackings(response.data.reverse());
      }
    };

    fetchTrackings();
  }, [contractAddress]);

  return (
    <div className="csv-upload-container">
      <div className="tabs-container">
        <Tab
          tabName="Upload New"
          htmlFor="uploadTab"
          defaultChecked={true}
          onChange={(event) => {
            if (event.target.checked) {
              setActiveTab("uploadTab");
            }
          }}
        />
        <Tab
          tabName="Track Uploads"
          htmlFor="trackingsTab"
          defaultChecked={false}
          onChange={(event) => {
            if (event.target.checked) {
              setActiveTab("trackingsTab");
            }
          }}
        />
      </div>
      {activeTab === "uploadTab" && (
        <div className="csv-uploader">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            {!showFile && (
              <div className="add-file-container">
                <p className="add-icon">+</p>
                <p className="add-text">Upload a CSV file</p>
                <p className="add-message">Click here to upload a CSV file or drag and drop a file here</p>
              </div>
            )}
          </div>
          <div>
            {csvFile && (
              <div className="file-preview-container">
                <FaFileCsv className="file-icon" />
                <IoCloseCircle
                  className="remove-file-btn"
                  onClick={() => {
                    if (uploading) return;

                    setCsvFile(null);
                    setShowFile(false);
                    setErrorMessages([]);
                  }}
                />
                <p className="file-name">{csvFile.name}</p>
                <button disabled={uploading} onClick={handleUpload} className="upload-file-btn">
                  Upload CSV File
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {activeTab === "trackingsTab" && (
        <div className="tracking-section">
          {trackings.length === 0 && (
            <div className="no-tracking-container">
              <TbFilesOff className="no-tracking-icon" />
              <p className="no-tracking-title">No tracking data found.</p>
              <p className="no-tracking-msg">Upload a CSV file to track the status of the upload.</p>
            </div>
          )}
          {trackings.map((tracking) => (
            <React.Fragment key={tracking._id}>
              <TrackingRow tracking={tracking} />
              {trackings.length >= 4 && trackings.indexOf(tracking) !== trackings.length - 1 && (
                <p className="border-bottom"></p>
              )}
              {trackings.length < 4 && <p className="border-bottom"></p>}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
