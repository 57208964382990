import card from "../assets/card.png";
import lyncLogo from "../assets/lync.png";
import lumosImg from "../assets/Claim_Page.jpg";
import collection from "../assets/collection.png";
import dna from "../assets/dna.png";
import metalTextureWithScrews from "../assets/metal-texture-with-screws.png";
import nftArt from "../assets/nft-art.png";
import sciGirl from "../assets/sci-girl.png";
import mumbaiLogo from "../assets/mumbai.png";
import ethGoerliLogo from "../assets/eth.png";
import arbitrumLogo from "../assets/Arbitrum.png";
import mantleLogo from "../assets/mantle.png";
import mantleL from "../assets/mantleL.png";
import astarLogo from "../assets/astar-.png";
import ethMainnetLogo from "../assets/ethmainnet.png";
import navigationImg from "../assets/navigation.png";
import filecoinLogo from "../assets/filecoin2.png";
import lyncblack from "../assets/lync-black.png";
import zksyncLogo from "../assets/zksync2.png";
import hederaLogo from "../assets/hedera2.png";
import bnb from "../assets/bnb.png";
import baseLogo from "../assets/base-logo.webp";
import polygonLogo from "../assets/polygon.svg";
import dkLogo from "../assets/dKloud-logo.png"

export const appImages = {
  card,
  lumosImg,
  collection,
  dna,
  metalTextureWithScrews,
  nftArt,
  sciGirl,
  lyncLogo,
  mumbaiLogo,
  navigationImg,
  ethGoerliLogo,
  arbitrumLogo,
  mantleLogo,
  astarLogo,
  ethMainnetLogo,
  mantleL,
  filecoinLogo,
  lyncblack,
  zksyncLogo,
  hederaLogo,
  bnb,
  baseLogo,
  polygonLogo,
  dkLogo
};
