import { ethers } from "ethers";
import React, { useContext, useRef, useState } from "react";
import Confetti from "react-confetti";
import { useDropzone } from "react-dropzone";
import { CgSpinnerAlt } from "react-icons/cg";
import { IoCheckmarkDoneSharp, IoCloseCircle, IoInformationCircle } from "react-icons/io5";
import { MdContentCopy } from "react-icons/md";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ContractFactory, Web3Provider } from "zksync-web3";
import { deployGAFunctions } from "../../GA/DeployGA";
import { AuthContext } from "../../context/AuthContext";
import constantsValues, { defautlChainId } from "../../constants/constantsValues";
import CheckFields from "../../helper/CheckFields";
import MetaMaskError from "../../helper/MetaMaskError";
import ApiCall from "../../helper/ApiCall";
import { APIRoutes } from "../../constants/ApiRoutes";
import { homeGAFunctions } from "../../GA/HomeGA";
import { walletConnect } from "../../helper/WalletConnect";
import ReactGA from "react-ga4";
import { lighthouseClient } from "../../helper/lighthouseClient";

export default function DeployFrameContract() {
  ReactGA.send("Deploy Frame Contract Page");
  const navigate = useNavigate();
  const loaction = useLocation();
  const { ipfsURL, metaData, s3url } = loaction?.state;
  const category = "collection";
  const { address, chainToConnect, setChainToConnect, provider, chainNotSupport } = useContext(AuthContext);
  //
  const frame_gaspayer_address = "0x0134eED60e9DF7D6c4CF2f2D318215213eBe7f1D";
  const frame_gaspayer_amount = "0.0004";

  console.log("frame_gaspayer_amount", frame_gaspayer_amount);
  //
  const [show, setShow] = useState(false);
  const [click, setClick] = useState(false);
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState(ipfsURL ? ipfsURL : null);
  const [name, setName] = useState("");
  const [symb, setSymb] = useState("");
  const [mintingReq, setMintingReq] = useState("");
  const [maxMintAllowedPerUser, setMaxMintAllowedPerUser] = useState("");
  const [addWhiteListuser, setAddWhiteListUser] = useState(false);
  const [alreadyPaidQty, setAlreadyPaidQty] = useState(0);

  const [formData, setFormData] = useState({
    name: "",
    symbol: "",
    uri: metaData,
    maxSupply: "1",
    biconomyKey: "",
    maxMintAllowedPerUser: "1",
    cost: "0",
    description: "Frame Mint",
    title: "Frame Mint",
  });

  const [contractType, setContractType] = useState("ERC1155Collection");
  const [isLoading, setLoading] = useState(false);
  const [deployState, setDeployState] = useState(-1);
  const [deployedContractAddress, setDeployedContractAddress] = useState("");
  const [showMintAmountError, setShowMintAmountError] = useState({
    inputId: "",
    showError: false,
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [".png", ".gif", ".jpeg", ".jpg"] },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        setImage(acceptedFiles[0]);
        setShow(true);
      } else {
        toast.error("Please upload image or gif");
        return;
      }
    },
    // noClick: true,
  });

  /** -------------------------------------------------------- */
  const firstDivRef = useRef();
  const secondDivRef = useRef();

  const handleScroll = (scroll) => {
    secondDivRef.current.scrollTop = scroll.target.scrollTop;
  };

  function handleMintAmount(event) {
    if (formData.maxMintAllowedPerUser === "" || formData.maxSupply === "") {
      setShowMintAmountError({
        inputId: "",
        showError: false,
      });
      return;
    }

    if (parseInt(formData.maxSupply) < parseInt(formData.maxMintAllowedPerUser)) {
      setShowMintAmountError({
        inputId: event.target.id,
        showError: true,
      });
    } else {
      setShowMintAmountError({
        inputId: "",
        showError: false,
      });
    }
  }

  if (!ipfsURL) {
    return <Navigate to="/" />;
  }
  //
  //
  function updateFormData(e) {
    setFormData((prv) => ({ ...prv, [e.target.id]: e.target.value }));
  }

  async function DeployContract() {
    const { name, symbol, uri, maxSupply, biconomyKey, maxMintAllowedPerUser, cost, title, description } = formData;
    let contractAddress;
    try {
      try {
        CheckFields(
          category,
          contractType,
          name,
          symbol,
          uri,
          maxSupply,
          biconomyKey,
          maxMintAllowedPerUser,
          cost,
          title,
          description,
          image
        );
      } catch (err) {
        toast.error(err.message);
        return;
      }

      setLoading(true);
      setDeployState(0);
      let imgurl;
      if (category === "collection") {
        if (ipfsURL) {
          const image_urlArr = ipfsURL?.split("//ipfs.io/ipfs/");
          imgurl = image_urlArr[1];
          imgurl = imgurl;
        } else {
          const uploadData = await lighthouseClient.uploadFile(image);
          if (!uploadData.success) {
            toast.error(uploadData.message);
            setLoading(false);
            setDeployState(null);
            return;
          }

          imgurl = uploadData.fileHash;
        }
      }
      const { res: isFundingRes, resData: fundingData } = await ApiCall(APIRoutes.getFundingStatus, "POST", {
        walletAddress: address,
        chainId: constantsValues[chainToConnect].chainID,
      });

      console.log("fundingData", fundingData);
      const qtyalreadypaid = fundingData?.data?.quantity ? fundingData?.data?.quantity : 0;

      console.log("qtyalreadypaid", qtyalreadypaid);
      setAlreadyPaidQty(qtyalreadypaid);

      // Compile Contract
      const { res, resData: response } = await ApiCall(APIRoutes.compileContract, "POST", {
        name,
        symbol,
        walletAddress: address,
        chainId: constantsValues[chainToConnect].chainID,
        networkType: constantsValues[chainToConnect].slug,
        contractType: contractType,
        uri,
        maxSupply,
        biconomyKey,
        forwarder: constantsValues[chainToConnect].forwarder,
        maxMintAllowedPerUser,
        category: "Frame",
        cost,
        title,
        description,
        image: imgurl,
        isWhiteListUser: addWhiteListuser,
      });
      if (res.status === 200) {
        //  send amount for 200 nft minting
        try {
          setDeployState(1);
          const signer = provider.getSigner();
          if (Number(formData.maxSupply) > qtyalreadypaid) {
            const transferAmount = (
              frame_gaspayer_amount * Number(formData.maxSupply - qtyalreadypaid).toString()
            ).toFixed(4);
            const tx = {
              to: frame_gaspayer_address,
              value: ethers.utils.parseEther(String(transferAmount), "ether"),
            };
            const transaction = await signer.sendTransaction(tx);
            console.log(transaction);

            await ApiCall(APIRoutes.insertFundingStatus, "POST", {
              walletAddress: address,
              chainId: constantsValues[chainToConnect].chainID,
              quantity: formData.maxSupply,
            });
          }
        } catch (error) {
          console.log(error);
          const errorMessage = MetaMaskError(error?.data?.message ? error?.data?.message : error.message);
          toast.error(errorMessage);
          setLoading(false);
          setDeployState(null);
          return;
        }

        //
        setDeployState(2);
        if (chainToConnect === "0x118") {
          const signer = new Web3Provider(window.ethereum).getSigner();
          const int = new ethers.utils.Interface(response.abi);
          const contract = new ContractFactory(int, response.bytecode, signer);
          let deploy = await contract.deploy({ type: 0 });
          setDeployState(3);
          await deploy.deployTransaction.wait(4);
          contractAddress = deploy.address.toLocaleLowerCase();
          setDeployedContractAddress(contractAddress);
        } else {
          const factory = new ethers.ContractFactory(response.abi, response.bytecode, provider.getSigner());
          if (chainToConnect === "0x128") {
            const contract = await factory.deploy({ gasLimit: "1000000" });
            await contract.deployed();
            setDeployState(3);
            await contract.deployTransaction.wait(5);
            contractAddress = contract.address.toLocaleLowerCase();
            setDeployedContractAddress(contractAddress);
          } else if (chainToConnect === "0x61") {
            const contract = await factory.deploy({ gasPrice: "20000000000" });
            await contract.deployed();
            setDeployState(3);
            await contract.deployTransaction.wait(5);
            contractAddress = contract.address.toLocaleLowerCase();
            setDeployedContractAddress(contractAddress);
          } else {
            const contract = await factory.deploy();
            await contract.deployed();
            setDeployState(3);
            await contract.deployTransaction.wait(5);
            contractAddress = contract.address.toLocaleLowerCase();
            setDeployedContractAddress(contractAddress);
          }
        }

        await ApiCall(APIRoutes.insertFundingStatus, "POST", {
          walletAddress: address,
          chainId: constantsValues[chainToConnect].chainID,
          quantity: Number(formData.maxSupply) > qtyalreadypaid ? 0 : qtyalreadypaid - formData.maxSupply,
        });

        // Verify Contract
        const { res, resData } = await ApiCall(APIRoutes.verifyContract, "POST", {
          contract_address: contractAddress,
          verifyId: response.verifyId,
          isFrameMinting: true,
          warpCastMintingRequired: mintingReq,
          s3Url: s3url ? s3url : imgurl,
        });
        if (res.ok) {
          setLoading(false);
          setDeployState(4);
          setName(formData.name);
          setSymb(formData.symbol);
          setMaxMintAllowedPerUser(formData.maxMintAllowedPerUser);
          setClick(true);
          setFormData({
            name: "",
            symbol: "",
            uri: "",
            maxSupply: "",
            biconomyKey: "",
            maxMintAllowedPerUser: "",
            cost: "",
            description: "",
            title: "",
          });
          if (resData.status) {
            toast.success(contractAddress + " Contract Deploy Successfully");
          } else if (resData.status) {
            toast.error(resData.result.result);
          }
          setLoading(false);
          setDeployState(null);
        } else {
          setLoading(false);
          setDeployState(null);
          console.log("contract verification failed");
        }
      } else {
        toast.error(response?.msg);
        setLoading(false);
        setDeployState(null);
      }
    } catch (err) {
      console.log(err);
      const errorMessage = MetaMaskError(err.message);
      toast.error(errorMessage);
      setLoading(false);
      setDeployState(null);
      return;
    }
  }
  let d1 = new Date();
  let str = d1.toISOString();
  if (click) {
    setTimeout(() => {
      navigate(`/contract/${deployedContractAddress}`, {
        state: {
          contractAddress: deployedContractAddress,
          contractName: name,
          category: "Frame",
          networkType: constantsValues[chainToConnect]?.slug,
          contractType: contractType,
          symbol: symb,
          chainId: chainToConnect,
          contractCreatedAt: str,
          isWhiteListUser: addWhiteListuser,
          maxMintAllowedPerUser: Number(maxMintAllowedPerUser),
          isFrameMinting: true,
        },
      });
    }, 5000);
  }

  const requirementForMinitng = ["Like", "Recast", "Follow"];
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  return (
    <>
      {click ? (
        <div
          style={{
            position: "absolute",
            left: "0",
            top: "0",
            width: "100%",
            height: "100vh",
          }}
        >
          <Confetti
            friction={1}
            numberOfPieces={600}
            recycle={false}
            width={window.innerWidth || 300}
            height={window.innerHeight || 200}
          />
        </div>
      ) : null}

      <div style={{ paddingInline: "0" }} className="new-contract mintMain">
        <>
          <main className="main-content">
            <article style={{ paddingTop: isLoading ? "0" : "2rem" }} className="content mintTop">
              <h1 className="content-heading">Write your contract details carefully</h1>
              <p className="content-description">
                Your contract name is a key identifier that appears wherever your contract is mentioned or listed.
                Typically, it reflects your artist name, collection name, brand, or project name.
              </p>

              <ul
                style={{
                  marginTop: "1rem",
                  fontWeight: 600,
                  letterSpacing: "0.01em",
                }}
                className="gas-fee-requirement-highlights"
              >
                <li className="requirement-points">
                  Required funds : {`${(frame_gaspayer_amount * formData.maxSupply).toFixed(4)} ETH`}
                </li>
              </ul>
              <div>
                {deployedContractAddress !== "" ? (
                  <a href={constantsValues[chainToConnect].blockExplorer + deployedContractAddress} target="_blank">
                    <button className="deploy-btn" disabled={isLoading}>
                      View on block explorer
                    </button>
                  </a>
                ) : chainNotSupport != true && chainToConnect ? (
                  <div
                    style={{
                      marginTop: "2.5rem",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {alreadyPaidQty > 0 ? (
                      <p
                        style={{
                          fontSize: "0.8rem",
                          color: "#cdcdcd",
                          fontWeight: 500,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <IoCheckmarkDoneSharp
                          style={{
                            color: "#61d345",
                            fontSize: "1rem",
                            marginRight: "5px",
                          }}
                          className="check-mark"
                        />
                        {alreadyPaidQty == 1 ? (
                          <small>Required funds for first edition is already paid for this account.</small>
                        ) : (
                          <small>
                            Required funds for first {alreadyPaidQty} editions are already paid for this account.
                          </small>
                        )}
                      </p>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    <button
                      style={{ width: "max-content", marginTop: "0.5rem" }}
                      className="deploy-btn"
                      form="deploy-form"
                      disabled={isLoading || !address}
                    >
                      {`Deploy On ${constantsValues[chainToConnect]?.ChainName}`}
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      homeGAFunctions.connectWalletEvent();
                      walletConnect(defautlChainId);
                    }}
                    className="deploy-btn"
                  >
                    {chainNotSupport && address ? "Switch Network" : "Connect Wallet"}
                  </button>
                )}
              </div>

              {isLoading ? (
                <div className="deployment-processing-states">
                  <div className="states-description">
                    <>
                      <p className="state-text">Compiling your contract</p>
                      {deployState >= 0 ? (
                        <span className="state-symbol">
                          {deployState === 0 ? (
                            <CgSpinnerAlt className="spinner" />
                          ) : (
                            <IoCheckmarkDoneSharp className="check-mark" />
                          )}
                        </span>
                      ) : null}
                    </>
                  </div>
                  <div className="states-description">
                    <>
                      <p className="state-text">
                        Send{" "}
                        {(
                          frame_gaspayer_amount *
                          Number(
                            formData.maxSupply > alreadyPaidQty ? formData.maxSupply - alreadyPaidQty : 0
                          ).toString()
                        ).toFixed(4)}{" "}
                        ETH for minting
                      </p>
                      {deployState >= 1 ? (
                        <span className="state-symbol">
                          {deployState === 1 ? (
                            <CgSpinnerAlt className="spinner" />
                          ) : (
                            <IoCheckmarkDoneSharp className="check-mark" />
                          )}
                        </span>
                      ) : null}
                    </>
                  </div>
                  <div className="states-description">
                    <>
                      <p className="state-text">Deploying your contract</p>
                      {deployState >= 2 ? (
                        <span className="state-symbol">
                          {deployState === 2 ? (
                            <CgSpinnerAlt className="spinner" />
                          ) : (
                            <IoCheckmarkDoneSharp className="check-mark" />
                          )}
                        </span>
                      ) : null}
                    </>
                  </div>
                  <div className="states-description">
                    <>
                      <p className="state-text">Verifying your contract</p>
                      {deployState >= 3 ? (
                        <span className="state-symbol">
                          {deployState === 3 ? (
                            <CgSpinnerAlt className="spinner" />
                          ) : (
                            <IoCheckmarkDoneSharp className="check-mark" />
                          )}
                        </span>
                      ) : null}
                    </>
                  </div>
                </div>
              ) : deployedContractAddress !== "" ? (
                <div
                  className="deployed-address-container"
                  onClick={() => {
                    navigator.clipboard.writeText(deployedContractAddress);
                    toast.success("Contract Address copied to clipboard.");
                  }}
                >
                  Your Deployed Contract Address is:
                  <div className="contract-address-display">
                    <p className="contract-address">{deployedContractAddress}</p>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(deployedContractAddress);
                        toast.success("Contract Address copied to clipboard.");
                      }}
                      className="copyBtn contractAddressCoptBtn"
                    >
                      <MdContentCopy />
                    </button>
                  </div>
                </div>
              ) : null}
            </article>
            <form
              className={category == "collection" ? "contract-information mBottom" : "contract-information"}
              id="deploy-form"
              onSubmit={(e) => {
                e.preventDefault();
                deployGAFunctions.deployOnBaseEvent();
                DeployContract();
              }}
            >
              {/* {category === "oneOnone" || category === "multiple" ? null : (
                  <>
                    {" "}
                    <div className="information-group">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        {!show ? (
                          <div className="image-cont">
                            <p className="add">+</p>
                            <p className="add_upload">Upload Image or GIF</p>
                          </div>
                        ) : null}
                      </div>
                      <div>
                        {files.map((path) => {
                          return (
                            <div className="information-group-img">
                              <img key={path} src={path.preview} />
                              <IoCloseCircle
                                className="remove-img-btn"
                                onClick={() => {
                                  setFiles([]);
                                  setImage(null);
                                  setShow(false);
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="information-group">
                      <label className="label">Title</label>
                      <input
                        className="name"
                        type="text"
                        placeholder="Title"
                        value={formData.title}
                        name="title"
                        id="title"
                        required={true}
                        onChange={(e) => updateFormData(e)}
                      />
                    </div>
                    <div className="information-group desc">
                      <label className="label">Description</label>
                      <textarea
                        className="name"
                        placeholder="Description"
                        type="text"
                        value={formData.description}
                        name="description"
                        id="description"
                        required={true}
                        onChange={(e) => updateFormData(e)}
                      />
                    </div>
                  </>
                )} */}
              <div className="information-group">
                <label className="label">Contract Name</label>
                <input
                  className="name"
                  type="text"
                  placeholder="Ex. - MetaArtToken"
                  value={formData.name}
                  name="name"
                  id="name"
                  required={true}
                  onChange={(e) => updateFormData(e)}
                />
              </div>

              <div className="information-group">
                <label className="label">Contract Symbol</label>
                <input
                  className="name"
                  type="text"
                  value={formData.symbol}
                  placeholder="Symbol"
                  name="symbol"
                  id="symbol"
                  required={true}
                  onChange={(e) => updateFormData(e)}
                />
              </div>
              <div className="information-group">
                <label className="label">Choose Chain</label>
                <select
                  className="name"
                  id="chain"
                  name="chain"
                  onChange={(e) => {
                    setChainToConnect(e.target.value);
                  }}
                  value={chainToConnect}
                >
                  {/* map on const obj. */}
                  {chainNotSupport === true ? <option>-- Select Chain --</option> : null}
                  {Object.keys(constantsValues).map((keyName, i) => (
                    <option value={keyName} key={i}>
                      {constantsValues[keyName].ChainName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="information-group">
                <label className="label">Minting Requirement </label>
                <select
                  className="name"
                  id="minting-req"
                  name="minting-req"
                  onChange={(e) => {
                    setMintingReq(e.target.value);
                  }}
                  value={mintingReq}
                >
                  <option value="">None</option>
                  {requirementForMinitng.map((keyName, i) => (
                    <option value={keyName} key={i}>
                      {keyName}
                    </option>
                  ))}
                </select>
                <ul
                  style={{
                    fontSize: "0.8rem",
                    color: "#6d6d6d",
                    fontWeight: 500,
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "0.35em",
                    marginLeft: "1.2rem",
                  }}
                  className="minting-action-list"
                >
                  <li style={{ listStyleType: "disc" }} className="minting-action-list-items">
                    <strong>None - </strong> Anyone can mint (less spammy)
                  </li>
                  <li style={{ listStyleType: "disc" }} className="minting-action-list-items">
                    <strong>Recast - </strong>Collector must cast or recast your frame link
                  </li>
                  <li style={{ listStyleType: "disc" }} className="minting-action-list-items">
                    <strong>Like - </strong>Collector must like your frame link
                  </li>
                  <li style={{ listStyleType: "disc" }} className="minting-action-list-items">
                    <strong>Follow - </strong>Collector must follow your account
                  </li>
                </ul>
              </div>
              <div className="information-group">
                <label className="label">Type</label>
                <select
                  className="name"
                  id="mySelect"
                  name="contractType"
                  value={contractType}
                  onChange={(e) => setContractType(e.target.value)}
                >
                  {/* {category === "collection" || category === "oneOnone" ? (
                      <option
                        value={
                          category === "collection"
                            ? "ERC721ACollection"
                            : "ERC721A"
                        }
                      >
                        ERC721A
                      </option>
                    ) : null} */}

                  {category === "collection" || category === "multiple" ? (
                    <option value={category === "collection" ? "ERC1155Collection" : "ERC1155"}>ERC1155</option>
                  ) : null}

                  {/* {category === "collection" &&
                    constantsValues[chainToConnect]?.forwarder ? (
                      <>
                        <option value="biconomy">
                          ERC721A with Biconomy (Gasless)
                        </option>
                        <option value="biconomy1155">
                          ERC1155 with Biconomy (Gasless)
                        </option>
                      </>
                    ) : null} */}
                </select>
              </div>
              <div className="information-group">
                <label className="label">Max Supply</label>
                <input
                  className="name"
                  type="number"
                  value={formData.maxSupply}
                  placeholder="Max. Supply"
                  id="maxSupply"
                  name="maxSupply"
                  min={1}
                  required={true}
                  onChange={(e) => updateFormData(e)}
                  onWheel={(e) => e.target.blur()}
                  onBlur={(event) => handleMintAmount(event)}
                  onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                />
              </div>
            </form>
          </main>
        </>
      </div>
    </>
  );
}
