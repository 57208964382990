import CSVFileValidator from "csv-file-validator";
import { csvConfig } from "./csvValidateConfigs";
import EncryptApiKey from "../../helper/EncryptApiKey";
import { APIRoutes } from "../../constants/ApiRoutes";

export const validate = async (file) => {
  try {
    const csvData = await CSVFileValidator(file, csvConfig);
    if (Object.keys(csvData.inValidData).length > 0) {
      return {
        success: false,
        data: csvData.data,
        errorMessage: csvData.inValidData,
      };
    }

    const userAddressSet = new Set();
    for (const row of csvData.data) {
      const userAddress = row.userAddress?.toLowerCase();

      if (userAddress && userAddressSet.has(userAddress)) {
        return {
          success: false,
          data: csvData.data,
          errorMessage: "Duplicate user address found in CSV. Please remove duplicates and try again.",
        };
      }

      userAddressSet.add(userAddress);
    }

    return { success: true, data: csvData };
  } catch (error) {
    console.error("Error validating CSV:", error);
    return { success: false, errorMessage: "Error validating CSV" };
  }
};

export const validateCsv = (file) => {
  if (!file) return { success: false, errorMessage: "Please upload a csv file." };
  if (file.type !== "text/csv") return { success: false, errorMessage: "Please upload a csv file." };

  return validate(file);
};

export const uploadCsv = async (file, contractAddress) => {
  try {
    const data = new FormData();
    data.append("file", file);
    data.append("contractAddress", contractAddress);

    const apiKey = await EncryptApiKey();
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}${APIRoutes.csvUpload}`, {
      method: "POST",
      headers: {
        "x-api-key": apiKey,
      },
      body: data,
    });

    if (!response.ok) {
      throw new Error("Error uploading CSV file", {
        status: response.status,
        statusText: response.statusText,
      });
    }

    const json = await response.json();
    return { success: true, data: json };
  } catch (error) {
    console.error("Error uploading CSV: ", { error });
    return { success: false, message: error.message };
  }
};

export const parseValidationErrors = (errorMessage) => {
  if (!errorMessage) return [];
  if (typeof errorMessage === "string") return [errorMessage];

  if (Array.isArray(errorMessage)) {
    return errorMessage.reduce((accumulated, error) => {
      return [...accumulated, ...parseValidationErrors(error)];
    }, []);
  }

  if (typeof errorMessage === "object") {
    return Object.values(errorMessage).reduce((accumulated, error) => {
      return [...accumulated, ...parseValidationErrors(error)];
    }, []);
  }

  return [];
};

export const fetchTrackingIds = async (contractAddress) => {
  try {
    const apiKey = await EncryptApiKey();
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}${APIRoutes.getTrackingIds}?contractAddress=${contractAddress}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Error fetching tracking status", {
        status: response.status,
        statusText: response.statusText,
      });
    }

    const json = await response.json();

    return {
      success: true,
      data: json,
    };
  } catch (error) {
    console.error("Error fetching Tracking Ids: ", { error });
    return {
      success: false,
      message: error.message,
    };
  }
};
